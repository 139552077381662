<template>
  <div :loading="loadings" style="position: relative">
    <el-card>
      <el-button
        type="primary"
        class="btn"
        style="margin-top: 20px"
        @click="returnBack"
        >返回</el-button
      >
      <el-descriptions :column="1" title="售后详情">
        <el-descriptions-item label="售后单号">{{
          orderDetails.code
        }}</el-descriptions-item>
        <el-descriptions-item label="订单号">{{
          orderDetails.orderCode
        }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{
          orderDetails.refundTime
        }}</el-descriptions-item>
        <el-descriptions-item label="售后状态">{{
          stateData[String(orderDetails.status)]
        }}</el-descriptions-item>
        <el-descriptions-item label="售后类型">
          {{
            orderDetails.type === 1
              ? "仅退款"
              : orderDetails.type === 2
              ? "退货退款"
              : "换货"
          }}</el-descriptions-item
        >
        <el-descriptions-item
          label="退款金额"
          v-if="orderDetails.type === 1 || orderDetails.type === 2"
        >
          {{ orderDetails.refundAmount }}</el-descriptions-item
        >
        <el-descriptions-item label="售后原因	">{{
          orderDetails.reason
        }}</el-descriptions-item>
        <el-descriptions-item
          v-if="orderDetails.status === 91"
          label="商家拒绝描述"
          >{{ orderDetails.rejectedRemark }}</el-descriptions-item
        >
        <div v-if="orderDetails.receiver">
          <el-descriptions-item label="买家姓名">{{
            orderDetails.receiver.name
          }}</el-descriptions-item>
          <el-descriptions-item label="买家收货手机号">{{
            orderDetails.receiver.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="买家收货地址">{{
            orderDetails.receiver.province +
            orderDetails.receiver.city +
            orderDetails.receiver.district +
            orderDetails.receiver.address
          }}</el-descriptions-item>
        </div>
      </el-descriptions>

      <el-card class="box-card" style="margin-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>售后进度</span>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in orderDetails.refundLocus"
            :key="index"
            :timestamp="activity.createTime"
            :color="index == 0 ? '#67C23A' : ''"
          >
            {{ activity.name }}
          </el-timeline-item>
        </el-timeline>
      </el-card>

      <div style="margin-bottom: 20px">
        <div style="font-size: 14px; margin-bottom: 20px">买家商品明细:</div>
        <el-table
          :data="orderDetails.refundItems"
          size="small"
          row-key="id"
          :border="true"
          :default-expand-all="false"
        >
          <el-table-column :align="'center'" label="商品名称" prop="goodsName">
          </el-table-column>
          <el-table-column :align="'center'" label="规格图片">
            <template slot-scope="scope">
              <div class="demo-image__preview">
                <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : ''"
                  :preview-src-list="[
                    scope.row.goodsSkuImg ? scope.row.goodsSkuImg : '',
                  ]"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :align="'center'"
            label="商品规格"
            prop="goodsSkuName"
          ></el-table-column>
          <el-table-column :align="'center'" label="数量" prop="quantity">
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- <el-card
        style="margin-top: 20px"
        v-if="orderDetails.status === 21 && orderDetails.type == 3"
      >
        <template
          v-if="
            orderDetails.refundItems.length > 0 &&
            orderDetails.refundItems[0].newGoodsSkuName
          "
        >
          <div style="margin-bottom: 20px">
            <div style="font-size: 14px; margin-bottom: 10px">
              换货商品明细:
            </div>

            <el-switch
              style="margin-bottom: 20px"
              v-model="isShow"
              active-text=""
              inactive-text="拆包发货"
            >
            </el-switch>
            <el-table
              :data="orderDetails.refundItems"
              size="small"
              row-key="id"
              :border="true"
              :default-expand-all="false"
            >
              <el-table-column
                :align="'center'"
                label="商品名称"
                prop="goodsName"
              >
              </el-table-column>
              <el-table-column :align="'center'" label="规格图片">
                <template slot-scope="scope">
                  <div class="demo-image__preview">
                    <el-image
                      style="width: 36px; height: 36px"
                      :src="
                        scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''
                      "
                      :preview-src-list="[
                        scope.row.newGoodsSkuImg
                          ? scope.row.newGoodsSkuImg
                          : '',
                      ]"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :align="'center'"
                label="商品规格"
                prop="newGoodsSkuName"
              >
              </el-table-column>
              <el-table-column :align="'center'" label="数量">
                <template slot-scope="scope">
                  <div v-if="!isShow">{{ scope.row.surplusNum }}</div>
                  <el-input-number
                    v-model="scope.row.surplusNum"
                    v-else
                    :min="1"
                    :max="scope.row.quantity - scope.row.receiverQuantity"
                    label=""
                  ></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-form
            ref="expressForms"
            :model="expressForm"
            :rules="rules"
            label-width="85px"
            size="mini"
          >
            <el-form-item label="物流公司" prop="expressCompanyCode">
              <el-select
                v-model="expressForm.expressCompanyCode"
                filterable
                placeholder="请选择物流公司"
              >
                <el-option
                  v-for="item in expressLists"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="物流单号" prop="expressNo">
              <el-input
                v-model="expressForm.expressNo"
                style="width: 30%"
                maxlength="20"
                placeholder="请填写物流单号"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <div style="display: flex; justify-content: flex-end">
                <el-button
                  type="primary"
                  :loading="loadings"
                  @click="submitExpress('forms')"
                  >确定发货</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-card> -->

    <el-card
      style="margin-top: 20px; position: relative"
      v-if="orderDetails.shipping && orderDetails.shipping.shippings.length"
    >
      <!-- <el-button type="primary" v-if="orderDetails.status == 20" class="btn" :loading="loadings" @click="submitOrder"
          >确定收货</el-button
        > -->

      <el-descriptions :column="1" :title="`用户物流信息`">
        <el-descriptions-item label="物流公司">{{  
          orderDetails.shipping.shippings[0].expressCompany
        }}</el-descriptions-item>
        <el-descriptions-item label="物流单号">{{
          orderDetails.shipping.shippings[0].expressNo
        }}</el-descriptions-item>
      </el-descriptions>

      <el-steps
        v-if="orderDetails.shipping.shippings[0].traces.length > 0"
        direction="vertical"
        :active="1"
        space="50px"
      >
        <el-step
          v-for="(i, index) in orderDetails.shipping.shippings[0].traces"
          :key="index"
          :title="i.AcceptStation"
          :description="i.AcceptTime"
          :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
        ></el-step>
      </el-steps>
      <div v-else>暂无轨迹信息</div>
    </el-card>

    <el-card
      style="margin-top: 20px"
      v-if="orderDetails.receiver && orderDetails.receiver.shippings.length"
    >
      <el-radio-group v-model="expressNum" style="margin-bottom: 30px">
        <el-radio-button
          :label="index"
          v-for="(item, index) in orderDetails.receiver.shippings"
          :key="index"
          >{{ "包裹" + (index + 1) }}</el-radio-button
        >
      </el-radio-group>

      <el-descriptions :column="1" :title="`商家发货明细`">
        <el-descriptions-item label="物流公司">{{
          orderDetails.receiver.shippings[expressNum].expressCompany
        }}</el-descriptions-item>
        <el-descriptions-item label="物流单号"
          >{{ orderDetails.receiver.shippings[expressNum].expressNo }}
        </el-descriptions-item>
      </el-descriptions>
      <el-table
        :data="orderDetails.receiver.shippings[expressNum].shippingItems"
        size="small"
        row-key="id"
        :border="true"
        :default-expand-all="false"
      >
        <el-table-column :align="'center'" label="商品名称" prop="goodsName">
        </el-table-column>
        <el-table-column :align="'center'" label="规格图片">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''"
                :preview-src-list="[
                  scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : '',
                ]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :align="'center'"
          label="商品规格"
          prop="newGoodsSkuName"
        >
        </el-table-column>
        <el-table-column :align="'center'" label="发货数量" prop="quantity">
        </el-table-column>
      </el-table>
      <el-steps
        direction="vertical"
        :active="1"
        space="50px"
        style="margin-top: 20px"
      >
        <el-step
          v-for="(i, index) in orderDetails.receiver.shippings[0].tracesCopy"
          :key="index"
          :description="i.AcceptStation"
          :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
        ></el-step>
      </el-steps>
    </el-card>

    <div
      v-if="orderDetails.status == 20"
      style="display: flex; justify-content: flex-end; margin-top: 20px"
    ></div>
  </div>
</template>

<script>
import {
  list,
  refundDetails,
  afterPageList,
  refundConfirm,
  refundShipping,
  getSupplierAddress,
} from "@/api/supplierGoods/after";
import { expressLogistics } from "@/api/supplierGoods/order";
import { afterState } from "./orders";
export default {
  name: "ReturnOrderInfo",
  data() {
    return {
      stateData: afterState, //状态对象数据
      orderDetails: {},
      loadings: false,
      isShow: false,
      expressNum: 0,
      orderId: "",
      expressForm: {
        expressCompanyCode: "",
        expressNo: "",
      },
      rules: {
        expressCompanyCode: [
          { required: true, message: "请选择物流公司：", trigger: "blur" },
        ],
        expressNo: [
          { required: true, message: "请填写物流单号", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.getOrderDetails();
  },
  methods: {
    returnBack() {
      this.$router.go(-1);
    },
    // 售后详情
    getOrderDetails() {
      refundDetails(this.orderId).then(async (res) => {
        if (res.data.refundItems.length) {
          res.data.refundItems.forEach((val) => {
            val.surplusNum = val.quantity - val.receiverQuantity;
          });
        }
        if (res.data.receiver && res.data.receiver.shippings.length) {
          res.data.receiver.shippings.map(async (item, index) => {
            res.data.receiver.shippings[index].tracesCopy =
              await this.getExpressLogistics(2, item.id, res.data.type);
          });
        }
        if (res.data.shipping && res.data.shipping.shippings.length) {
          res.data.shipping.shippings[0].traces =
            await this.getExpressLogistics(
              1,
              res.data.shipping.shippings[0].id,
              res.data.type
            );
        }
        setTimeout(() => {
          this.orderDetails = res.data;
        }, 1000);
      });
    },
    async getExpressLogistics(type, id, locType) {
      const res = await expressLogistics({
        type: locType,
        id,
      });
      if (type === 1) {
        return res.data.traces != "暂无轨迹信息"
          ? JSON.parse(res.data.traces)
          : [];
      }
      if (type === 2) {
        return res.data.traces != "暂无轨迹信息"
          ? JSON.parse(res.data.traces)
          : [];
      }
    },
    // 确认收货
    submitOrder() {
      this.$confirm("确认收到当前用户退货吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const res = await refundConfirm({ refundId: this.orderDetails.id });
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getOrderDetails();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 换货确认发货
    submitExpress() {
      this.$refs["expressForms"].validate(async (valid) => {
        if (valid) {
          this.loadings = true;
          let items = this.orderDetails.refundItems.map((val) => {
            return {
              goodsId: val.goodsId,
              goodsSkuId: val.goodsSkuId,
              quantity: val.surplusNum,
            };
          });
          let res = await refundShipping({
            items,
            ...this.expressForm,
            refundId: this.orderDetails.id,
          });
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getList();
            this.isShow = false;
            this.$refs["expressForms"].resetFields();
            this.expressForm.expressCompanyCode = "";
            this.expressForm.expressNo = "";
          }

          this.loadings = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 33%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;
  &:before {
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title {
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }
  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img {
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th {
  background: #f0f5ff;
}
.btn {
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>
